export default {
    async setDetailData(data){
     
        const localePath = useLocalePath();
        const router = useRouter()


        this.commitMutation('resetWebshopDetail')

        try{
            const response = await fetch(useCountryPath("/config/shop/detail/" + data.slug));
            const res = await response.json();

            if (!res.success) {
                throw this.app.context.error(  { message :'missing data property in response setDetailData webshop', status : 500}  )
            }
            if ( res.data.full_slug == '' ) {
                if (import.meta.client) {
                    return router.push(localePath({name: 'shop-all'}));
                }
    
                if ( import.meta.server) {
                    return this.app.context.redirect( 302, localePath('/wedding-rings/anniversary-rings'))
                }
            }
            const editedPath = data.fullPath.replace('/in/', '/')
            
            if (res.data.full_slug !== editedPath) {
                if (import.meta.client) {
                    return router.push( localePath(res.data.full_slug) )
                }
    
                if ( import.meta.server) {
                    return this.app.context.redirect( 302, localePath(res.data.full_slug))
                }
            }
            // console.log('1')
            await this.commitMutation('setDetailData',{ data: res.data, fastShip: data.fastShip})
            // console.log('2')
            await this.setSizeData({ slug: data.slug, fastShip: data.fastShip })
            // console.log('3')
            await this.setInstockData({ slug: data.slug })
            // console.log('4')
            return res.data
           
          
        }catch(error){
            if (error.response?.status === 404 || error.response?.status === 400) {


                // check if redirection exists
                try{
                    const queryParams = router.currentRoute.query
                    
                    const data = await this.$axios.$get( process.env.baseURL+'redirect',  { params : { path :  router.currentRoute.path } } )

                    if (data.data?.url) {

                        
                        let url = data.data?.url.new_url
                        if(queryParams){
                            const query = Object.entries(queryParams).map(([key,value]) => `${key}=${value}`).join('&');
                            url = `${data.data?.url.new_url}?${query}`
                        }

                        this.app.context.redirect( 302, localePath(url))
                    }

                }catch( e2 ){

                    throw new Error(e2.message || 'Failed to fetch in-stock data');
                }



                // console.log(  { message :'product not found cyoDetailApiService', status : 404, error} )
                throw this.app.context.error(  { message :'product not found detail webshop', status : 404}  )
            }

            throw error
        }
       
    },

    async setInstockData(data){
      
        try{

            const response = await fetch(useCountryPath("/shop/in-stock/" + data.slug));
            const res = await response.json();
            if(res.success){
                // console.log(res, 'res.datares.datares.data')
                this.commitMutation( 'setInstockData', res.data )
                this.commitMutation( 'setInStockMetals', res.data )
                return res.data
            }
        
        }catch(error){
            throw error
            // if (error.response?.status === 404 || error.response?.status === 400) {
            //     // check if redirection exist
            // }
            // // console
            // console.log('eerror', error)
            // throw new Error(error.message || 'Failed to fetch in-stock data');
        }
       
    },

    async setSizeData(data){
        
        try{
            const response = await fetch(useCountryPath("/config/shop/size/detail/" + data.slug));
            const res = await response.json();
            if(res.success){
               
                this.commitMutation('setSizeData',{sizeData : res.data, data:data})
                return res.data
            }
        
        }catch(error){
            throw error
            if (error.response?.status === 404 || error.response?.status === 400) {
                // check if redirection exist
            }
            // console
            return null

            // throw error
        }
       
    },
   
    async setAdditionalDetails(data){
        
        try{
            // console.log( 'this.route.slug', this.$route.params.slug )
            const response = await fetch(useCountryPath("/config/shop/attribute/detail/" + data.slug));
            const res = await response.json();

            if(res.success){
                this.commitMutation('setAdditionalDetails',res.data)
                return res.data
            }
        
        }catch(error){
            throw error
            if (error.response?.status === 404 || error.response?.status === 400) {
                // check if redirection exist
            }
            // console
            return null

            // throw error
        }
       
    },

    async setActive(data){
        const router = useRouter()
        await this.commitMutation('setActive', data)
        //get fastship from route
        let fastShip = false
        console.log(router.currentRoute.value.query.FastShip, 'setActivesetActivesetActive')
        // return
        if(router.currentRoute.value.query?.FastShip){
            fastShip = true
        }

        //if carat and metal toggeled call setSize and setAttribute again
        if(['carat', 'metal'].includes(data.type) && !fastShip){
            const slug = this.getCurrentActive('carat').slug
            await this.setSizeData({ slug: slug, fastShip: fastShip })
            await this.setAdditionalDetails({ slug: slug, fastShip: fastShip })
        }
        
    }
}