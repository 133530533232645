
import { cyodetailStore }  from '@/stores/cyodetail/cyodetail';
import { shopdetailStore }  from '@/stores/shop_detail/shop_detail';

export function useSeo() {
	const seo = ref({
		meta_title: '',
		meta_description: '',
		meta_keyword: '',
		page_title: '',
		page_description: '',
		canonical_url: '',
		alternate_url: '',
	})

	
	const schema = ref('')
	const brand_name = ref('Friendly Diamonds')
	const brand_currency = ref('USD')
	const locales = ['in', 'us']

	const localPath = useLocalePath()


	const route = useRoute()
	const router = useRouter()
	//   const store = useStore()
	//   const country = useCountry()
	const config = useRuntimeConfig()
	const { $country: country } = useNuxtApp()
	const { locale } = useI18n()

	const isReturns = computed(() => {
		const routeName = route.name
		const routeParamsType = route.params.type

		if (['instock_earring___us', 'instock_ring___us', 'instock_pendant___us'].includes(routeName)) {
			return true
		}
		if (['ready-to-ship-engagement-rings', 'ready-to-ship-pendants', 'ready-to-ship-earrings'].includes(routeParamsType)) {
			return true
		}
		return false
	})

	const getAlternateUrls = (urls) => {

		return urls.map( (cv) => {

			const path = getFullUrl( localPath( {name:extractRouteName(route.name), params: {slug:cv.url}}, cv.locale ) )

			return {
				rel: 'alternate',
				hreflang: cv.hreflang,
				href:path
			}
		})
	}


  	const setHead = () =>{
		useHead(() => ({
		    title: seo.value.meta_title,
		    meta: [
				{ name: 'description', content: seo.value.meta_description },
				{ name: 'keywords', content: seo.value.meta_keyword },
				{ property: 'og:type', content: 'website' },
				{ property: 'og:url', content: seo.value.canonical_url },
				{ property: 'og:title', content: seo.value.meta_title },
				{ property: 'og:description', content: seo.value.meta_description },
				{ property: 'og:site_name', content: 'Friendly Diamonds' },
				{ property: 'twitter:title', content: seo.value.meta_title },
				],
		    link: [
				{ rel: 'canonical', href: config.public.domain + seo.value.canonical_url },
				...(isReturns.value ? [] : seo.value.alternate_url) 
				// ...(isReturns.value ? [] : [{ rel: 'alternate', hreflang: country.language, href: seo.value.alternate_url }]),
				// { rel: 'alternate', hreflang: `en-${country.location}`, href: config.public.domain + route.path },
		    ],
		    script:  [{
				type: 'application/ld+json',
				innerHTML: JSON.stringify(schema.value.json),
			}],
		}))
	}

	

	const capitalizeAllWords = (str) => {
		if (!str) return str
		return str
		.split(' ')
		.map(word => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
	}

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
	}

	const setGiftCardDetailMetatags = (data) => {
		let schemaData = ""

		seo.value.meta_title = data.meta_title
		seo.value.meta_description = data.meta_description
		seo.value.meta_keyword = data.meta_keyword

		seo.value.page_title = data.product_name
		seo.value.page_description = data.meta_description
		seo.value.canonical_url = config.public.domain + route.path

		schemaData = getDefaultProductSchema()

		schemaData.json.name = data.product_name
		if (data.images && data.images.front_image) {
		schemaData.json.image = [data.images.front_image]
		}
		schemaData.json.description = data.description
		schemaData.json.sku = data.id
		schemaData.json.mpn = data.id
		schemaData.json.offers.url = config.public.domain + route.fullPath
		schemaData.json.offers.price = data.price

		schema.value = schemaData
	}
	// ok
	const setCyoSettingDetailMetaTags = () => {
		const store = cyodetailStore();

		let schemaData = ""

		const item = store.getSettingDetail(route.meta.moduleSubType)
		const metal = store.getSettingActiveVariation(route.meta.moduleSubType)
		const images = store.getCyoSliderImages(route.meta.moduleSubType, 'setting')

		if (!item) return

		seo.value.meta_title = item.meta_title
		seo.value.meta_description = item.meta_description
		seo.value.meta_keyword = item.meta_keyword

		seo.value.page_title = item.product_name
		seo.value.page_description = item.product_name
		seo.value.canonical_url = route.path
		if( item?.alternate_urls?.length > 0 ){
			
			seo.value.alternate_url = getAlternateUrls( item.alternate_urls )
		}

		schemaData = getDefaultProductSchema()

		schemaData.json.name = item.product_name
		schemaData.json.image = [images.front_image]
		schemaData.json.description = item.description
		schemaData.json.sku = item.main_sku
		schemaData.json.mpn = item.main_sku
		schemaData.json.offers.url = route.fullPath
		schemaData.json.offers.price = metal.price

		schema.value = schemaData
	
		setHead()
	}
	// ok
	const setCyoDiamondDetailMetaTags = () => {

		const store = cyodetailStore();
		let schemaData = ""
		const item = store.getDiamondDetail()
		const images = store.getCyoSliderImages(route.meta.moduleType, 'diamond')

		
		if (!item) return

		seo.value.meta_title = item.meta_title
		seo.value.meta_description = item.meta_description
		seo.value.meta_keyword = item.meta_keyword

		seo.value.page_title = item.product_name
		seo.value.page_description = item.product_name

		// set canonical to loose diamonds

		const looseDetailRouteName = 'loose-detail'

		if( item?.shape_two ){

			const routeName = 'cyo-diamond-detail-earring'
			seo.value.canonical_url = localPath( {name: routeName, params: {slug: route.params?.slug }}, locale.value )
		}
		else if( route.params?.slug ){

			const routeName = 'loose-detail'
			seo.value.canonical_url = localPath( {name: routeName, params: {slug: route.params?.slug }}, locale.value )
		}

		if(route.params?.slug){

			seo.value.alternate_url = locales.map( (cv) => {
	
				const path = getFullUrl( localPath( {name:extractRouteName(route.name), params: {slug: route.params?.slug }}, cv ) )
	
				return {
					rel: 'alternate',
					hreflang: cv.hreflang,
					href:path
				}
			})
		}

		schemaData = getDefaultProductSchema()

		if(route.meta.moduleSubType === 'earring'){

			schemaData.json.name = item.title

			if( images && images.front_image ){
				schemaData.json.image =  [ images.front_image ]   
			}

			schemaData.json.description   =  item.description_one
			schemaData.json.sku           =  item.lot_one + '_' +item.lot_two
			schemaData.json.mpn           =  item.lot_one + '_' +item.lot_two
			schemaData.json.offers.url    =  route.fullPath
			schemaData.json.offers.price  =  item.price_total
			
		}else{
			
			schemaData.json.name =  item.title
			if(images && images.front_image ){
				
				schemaData.json.image  =  [ images.front_image ]   
			}
			
			schemaData.json.description   =  item.description
			schemaData.json.sku           =  item.lot
			schemaData.json.mpn           =  item.lot
			schemaData.json.offers.url    =  route.fullPath
			schemaData.json.offers.price  =  item.price
			
		}
		
		schema.value = schemaData

		if (route.meta.moduleSubType === 'earring' && item.price_total === "") {
			schema.value = ""
			setHead()
			return
		}
		if (route.meta.moduleSubType !== 'earring' && item.price === "") {
			schema.value = ""
			setHead()
			return
		}

		setHead()
	}
	// ok



	const setJewelryMetatags = () => {
		
		const store = shopdetailStore();
		
		let schemaData = ""

		const item = store.getDetailData
		const metal = store.getCurrentActive('metal')
		const carat = store.getCurrentActive('carat')
		const images = store.getWebshopSliderImages
		
		if (!item) return

		seo.value.meta_title = item.meta_title
		seo.value.meta_description = item.meta_description
		seo.value.meta_keyword = item.meta_keyword

		seo.value.page_title = metal.product_name
		seo.value.page_description = metal.product_name
		seo.value.canonical_url = route.path

		schemaData = getDefaultProductSchema()

		schemaData.json.name = metal.product_name
		if (images && images.front) {
			schemaData.json.image = [images.front]
		}
		schemaData.json.description = carat.long_desc
		schemaData.json.sku = metal.main_sku
		schemaData.json.mpn = metal.main_sku
		schemaData.json.offers.url = route.path
		schemaData.json.offers.price = carat.price

		schema.value = schemaData

		if( item?.alternate_urls?.length > 0 ){
			
			seo.value.alternate_url = getAlternateUrls( item.alternate_urls )
		}
		setHead()
	}

	// ok
	const setCyoCompleteMetatags = () => {
		let countryFlag = ''
		if (country.is_india) {
			countryFlag = ' - FD (IN)'
		}
		const store = cyodetailStore();
		const diamond = store.getDiamondDetail(route.meta.moduleSubType)
		const setting = store.getSettingDetail(route.meta.moduleSubType)

		if (!diamond || !setting || !setting.metals) {
			return
		}

		const metal = setting.metals.find(cv => cv.selected === 1)

		if (route.meta.moduleSubType === 'earring' && diamond && setting) {
			const title = `${setting.short_name} in ${metal.label} with ${diamond.title} ${countryFlag}`
			const description = `Shop ${setting.short_name} in ${metal.label} with ${diamond.title} of color ${diamond.color_one}-${diamond.color_two}, clarity ${diamond.clarity_one}-${diamond.clarity_two} cut ${diamond.cut_grade_one} at `

			seo.value.meta_title = capitalizeAllWords(title)
			seo.value.meta_description = capitalizeFirstLetter(description) + 'Friendly Diamonds.'
		}

		if (route.meta.moduleSubType !== 'earring' && diamond && setting) {
			const title = `${diamond.weight} ${diamond.shape} ${setting.short_name} in ${metal.label} ${countryFlag}`
			const description = `Shop ${setting.short_name} in ${metal.label} with the ${diamond.weight} ${diamond.shape} lab grown diamond of ${diamond.color} color and ${diamond.clarity} clarity at `

			seo.value.meta_title = capitalizeAllWords(title)
			seo.value.meta_description = capitalizeFirstLetter(description) + 'Friendly Diamonds.'
		}

		seo.value.canonical_url = router.currentRoute.value.fullPath
		setHead()
	}

	// ok
	const setPresetMetatags = async (data) => {
		
		let schemaData = ""

		seo.value.meta_title = data.meta_title
		seo.value.meta_description = data.meta_description
		seo.value.meta_keyword = data.meta_keyword

		seo.value.page_title = data.meta_title
		seo.value.page_description = data.meta_description
		seo.value.canonical_url = route.fullPath

		schemaData = getDefaultProductSchema()

		schemaData.json.name = data.meta_title
		schemaData.json.image = [data.images.front_image]
		schemaData.json.description = data.meta_description
		schemaData.json.sku = data.settingComponent.main_sku
		schemaData.json.mpn = data.settingComponent.main_sku
		schemaData.json.offers.url = route.fullPath
		schemaData.json.offers.price = data.price

		schema.value = schemaData
		if( data?.settingComponent?.alternate_urls.length > 0 ){
			
			seo.value.alternate_url = getAlternateUrls( data.settingComponent.alternate_urls )
		}

		setHead()
	}

	const globalMetatags = () => {

		const IndiaHeaders = {
			
			htmlAttrs: {
				lang: 'en-IN'
			},
			meta: [
				{ charset: 'utf-8' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
				//{ property: 'og:site_name', content: 'Friendly Diamonds' },
				{ name: 'robots', content: config.public.meta },
				// { name: 'googlebot-news', content: 'nosnippet' },
			
			],
			script: [{ 
				type: 'application/ld+json', 
			
				children: JSON.stringify({
					"@context":"http://schema.org",
					"@type":"Organization",
					"name":"Friendly Diamonds India",
					"url":"https://friendlydiamonds.com/in/",
					"logo":"https://assets.friendlydiamonds.com/fdl/v2/images/logo.svg",
					"brand":"Friendly Diamonds",
					"location":[
						{
							"@type":"PostalAddress",
							"streetAddress":"Salasar Estate, Ground Floor, 1-8 Yadav Nagar, Dahisar East",
							"addressLocality":"Mumbai",
							"addressRegion":" ",
							"postalCode":"400068"
						}
					],
					"telephone":"",
					"sameAs":[
						"https://www.instagram.com/friendlydiamondsindia"
					]
				}) 
			}]
		};

		const USHeaders = {
			htmlAttrs: {
				lang: 'en-US'
			},
			meta: [
				{ charset: 'utf-8' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
				//{ property: 'og:site_name', content: 'Friendly Diamonds' },
				{ name: 'robots', content: config.public.meta },
				// { name: 'googlebot-news', content: 'nosnippet' },
			
			],
			script: [{ 
				type: 'application/ld+json', 
				children: JSON.stringify({
					"@context":"http://schema.org",
					"@type":"Organization",
					"name":"Friendly Diamonds",
					"url":"https://friendlydiamonds.com/",
					"logo":"https://assets.friendlydiamonds.com/fdl/v2/images/logo.svg",
					"brand":"Friendly Diamonds",
					"location": [{
						"@type": "PostalAddress",
						"streetAddress": "20 W 47th St, Suite #716",
						"addressLocality": "New York",
						"addressRegion": "NY",
						"postalCode": "10036"
					}],
					"telephone": "1 646 956 4572",
					"sameAs":[
						"https://www.facebook.com/FriendlyDiamonds",
						"https://twitter.com/Friendldiamonds",
						"https://www.instagram.com/friendly.diamonds",
						"https://www.pinterest.com/friendly_diamonds",
						"https://www.youtube.com/@friendly.diamonds"
					]
				}) 
			}]
		};

		let commonHeaders
		if(country.location === 'IN'){
			if(['/in', '/in/'].includes(route.path)){
				IndiaHeaders.script.push({
					type: 'application/ld+json', 
					json: {
						"@context": "http://schema.org",
						"@id": "https://friendlydiamonds.com/in/#webpage",
						"@type": "WebPage",
						"url": "https://friendlydiamonds.com/in/",
						"name": "Friendly Diamonds India"
					}
				})
			}
		
			commonHeaders = IndiaHeaders
		}else{
			if(route.path === '/'){
				USHeaders.script.push({
					type: 'application/ld+json', 
					json: {
						"@context": "http://schema.org",
						"@id": "https://friendlydiamonds.com/#webpage",
						"@type": "WebPage",
						"url": "https://friendlydiamonds.com/",
						"name": "Friendly Diamonds"
					}
				})
			}
			commonHeaders = USHeaders
		}
		useHead(commonHeaders)
	}

	const setMetaTags = async (data = false) => {


		const route = useRoute()
		if (route.name.includes('cyo-setting-detail')) {
		
			return setCyoSettingDetailMetaTags()
		}
		

		if (route.name.includes('cyo-diamond-detail')) {

			return setCyoDiamondDetailMetaTags()
		}

		if (route.name.includes('loose-detail')) {
			return setCyoDiamondDetailMetaTags()
		}

		if (route.name.includes('shop-detail')) {
			return setJewelryMetatags()
		}

		if (route.name.includes('preset-detail')) {
			
			return setPresetMetatags(data)
		}
		if (route.name.includes('cyo-complete-ring')) {
			return setCyoCompleteMetatags()
		}

		if (route.name.includes('cyo-complete-pendant')) {
			return setCyoCompleteMetatags()
		}

		if (route.name.includes('cyo-complete-earring')) {
			return setCyoCompleteMetatags()
		}

		const disableAlternateTag = ['gifts-under-50000', 'gifts-under-80000', 'gifts-under-100000', 'gifts-under-1000', 'gifts-under-2000', 'gifts-under-3000']
	
		const setData = ( data)  => {

			if(data?.success && data?.data){
				seo.value = data.data


				seo.value.alternate_url = locales.map( (cv) => {
					const path = getFullUrl( localPath( {name:extractRouteName(route.name), params: {start_with: route.params?.start_with }}, cv ) )
					return {
						rel: 'alternate',
						hreflang: `en-${cv.toUpperCase()}`,
						href:path
					}
				})
				if(route.params?.start_with && disableAlternateTag.includes( route.params.start_with ) ){

					seo.value.alternate_url = []
				}

				setHead()
			}		
		}

		let urlPath = route.path.endsWith("/") ? route.path.slice(0, -1) : route.path

		const url = useCountryPath('/config/meta?path=' + urlPath)
	
		const { data: res } = await useAsyncData( 'seoMetaTags', () =>  $fetch( url, {
			method:'GET'
			}) 
		)
		
		if( res.value?.data ){
			
			setData(res.value)
		}else{

			watch( 
				res,
				(nv, ov) => setData( nv )
			)
		}
	}

	const getDefaultProductSchema = () => {
		if (country.is_india) {
		brand_name.value = 'Friendly Diamonds India'
		brand_currency.value = 'INR'
		}
		return {
		
			json:{
				"@context": "https://schema.org/",
				"@type": "Product",
				name: '',
				image: [''],
				description: '',
				sku: '',
				mpn: '',
				brand: {
					"@type": "Brand",
					"name": brand_name.value
				},
				offers: {
					"@type": "Offer",
					url: '',
					priceCurrency: brand_currency.value,
					price: '',
					priceValidUntil: "2030-11-20",
					itemCondition: "https://schema.org/NewCondition",
					availability: "https://schema.org/InStock",
					seller: {
						"@type": "Organization",
						"name": brand_name.value
					},
					shippingDetails: {
						"@type": "OfferShippingDetails",
						transitTimeLabel: "Transit time",
						transitTime: {
							"@type": "ShippingDeliveryTime",
							businessDays: "2-3"
						},
						deliveryLocation: {
							"@type": "DeliveryTimeSettings",
							shippingDestination: {
								"@type": "DefinedRegion",
								"name": "All destinations"
							},
							hasDeliveryMethod: {
								"@type": "DeliveryMethod",
								"name": "Delivery at customer's address"
							}
						}
					}
		
				}
			}
		
		}
	}

	return {
		seo,
		schema,
		isReturns,
		globalMetatags,
		capitalizeAllWords,
		capitalizeFirstLetter,
		setGiftCardDetailMetatags,
		setCyoSettingDetailMetaTags,
		setCyoDiamondDetailMetaTags,
		setJewelryMetatags,
		setPresetMetatags,
		setMetaTags,
		getDefaultProductSchema
	}
}
